/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import {checkIsActive, toAbsoluteUrl} from "../../../../_helpers";
import axios from "axios";
import {needRegisterPartner, showPartnerAddForm} from "../../../../../app/modules/Partners/redux/parnerActions";
import {useDispatch} from "react-redux";
import {updateManager} from "../../../../../app/modules/Catalog/redux/managerActions";

export function HeaderMenu({ layoutProps }) {

  const dispatch = useDispatch();

  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  const [partners, setPartners] = useState([]);
  const [current, setCurrent] = useState();

  const loadData = async () => {
    let response = await axios.get("Contragents/All");
    const {contragents} = response.data;

    response = await axios.get("Contragents/Current");
    const current = response.data;

    return {partners: contragents, current: current}
  }

  useEffect(() => {
    loadData().then(({partners, current}) => {
      if (partners && partners.length > 0) {
        dispatch(needRegisterPartner(false));
      }
      setPartners(partners);
      setCurrent(current.id);

      dispatch(updateManager({
        name: current.managerName,
        phone: current.managerPhone,
        email: current.managerEmail
      }))
    }).catch(e => {
      dispatch(needRegisterPartner(true));
    });
  }, []);

  const setPartner = (id) => {
    axios.post(`Contragents/Select?id=${id}`, {})
      .then(() => {
        window.location.reload(true);
      })
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      <li className={`menu-item menu-item-rel ${getMenuItemActive('/catalog')}`}>
        <NavLink className="menu-link" to="/catalog">
          <span className="menu-text">Каталог</span>
        </NavLink>
      </li>
      <li className={`menu-item menu-item-rel ${getMenuItemActive('/info')}`}>
        <NavLink className="menu-link" to="/info">
          <span className="menu-text">Информация</span>
        </NavLink>
      </li>
      <li className={`menu-item menu-item-rel ${getMenuItemActive('/contacts')}`}>
        <NavLink className="menu-link" to="/contacts">
          <span className="menu-text">Контакты</span>
        </NavLink>
      </li>
      <li
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/custom')}`}
      >
        {
          partners && partners.length > 0 ?
            <>
              <NavLink className="menu-link menu-toggle" to="/custom">
                <span className="menu-text">Выбор партнера</span>
                <i className="menu-arrow"/>
              </NavLink>
              <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                <ul className="menu-subnav">
                {
                  partners.map(partner => (
                    <li className={`menu-item ${partner.id === current ? "menu-item-active" : ""}`}>
                      <a className="menu-link" href="#" onClick={() => setPartner(partner.id)}>
                          <span className="svg-icon menu-icon">
                            <img src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} alt={partner.companyName}/>
                          </span>
                        <span className="menu-text">{partner.companyName}</span>
                      </a>
                    </li>
                  ))
                }
                </ul>
              </div>
            </> :
              <span className="menu-link" onClick={() => dispatch(showPartnerAddForm())}>
                <span className="menu-text">Добавить контрагента</span>
              </span>
        }
      </li>
    </ul>
  </div>;
}
