/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, Switch, Redirect, Route} from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import RegistrationSuccess from "./RegistrationSuccess";
import {shallowEqual, useSelector} from "react-redux";
import RegistrationConfirm from "./RegistrationConfirm";
import {Logout} from "../index";
import EmailConfirm from "./EmailConfirm";

export function AuthPage() {
  const { isAuthorized, isEmailConfirmed } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      isEmailConfirmed: auth.user?.emailConfirmed
    }),
    shallowEqual
  );

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundColor: "#f4f4f5",
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/images/logo-big.png")}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h3 mb-5">Добро пожаловать на B2B портал Дилеров RONTA!</h3>
                <p className="font-weight-lighter opacity-80">
                    Производственная компания RONTA, развивает дилерскую сеть на территории России и предлагает специальные условия для сотрудничества с оптовиками, дилерами и другими заинтересованными организациями.
                    <h3 className="font-size-h4 mb-5">Возможности B2B портала:</h3>
                    <li><b>Строка умного поиска (фильтра) по каталогу продукции</b> – это возможность быстро найти интересующую продукцию даже по части названия, артикула, цвета, вида продукции –  абсолютно любое сочетание. Например «жен гор бр» и в списке продукции останется только «Костюм рабочий женский летний "Город" с брюками (темно-серый/светло-серый/черный)».</li>
                    <li><b>Отгрузка со всех складов компании: производственного, оптового, корпоративного и розничного</b> – количество доступного к отгрузке товара, сроки поступления продукции на соответствующий склад и отпускные цены разные для каждого из складов. Это дает большие возможности выбора условий сотрудничества с нами.</li>
                    <li><b>Доступ всем менеджерам ко всеми операционными компаниями</b>– гибкая настройка доступа к порталу позволяет предоставить доступ к заказу продукции, просмотру наличия и цен не ограниченному количеству ваших сотрудников, а так же к неограниченному количеству ваших операционных компаний.</li>
                    <li><b>Доступ 24/7 к остаткам, срокам поступления и ценам</b> – вся информация о свободных остатках, планах поступления продукции в режиме реального времени, а также невероятно быстрая работа портала благодаря новейшим технологиям доступным сегодня.</li>
                    <h3 className="font-size-h4 mb-5">Преимущества дилеров RONTA:</h3>
                    <li>Дилерские цены (самые выгодные).</li>
                    <li>Приоритетное резервирование продукции на складе, в том числе в случае участия дилера в тендере (защита сделок, проектов и спец условия при тендерных поставках).</li>
                    <li>Возможность приобретать продукцию с отсрочкой оплаты.</li>
                    <li>Предоставление выставочных стендов и новинок ассортимента бесплатно.</li>
                    <li>Размещение информации о дилере на сайте.</li>
                    <li>Поддержка рекламными материалами, обеспечение всей необходимой методической, справочной и информационной продукцией.</li>
                    <li>Оперативная консультация по всем возникающим вопросам персонального менеджера.</li>
                    <li>Приоритетная отгрузка продукции в кратчайшие сроки.</li>
                    <h3 className="font-size-h4 mb-5">Оперативно ответим на любые вопросы и поможем!</h3>
                    <h3 className="font-size-h4 mb-5">Отдел сопровождения оптовых продаж:</h3>
                    <p><a href="tel:+79250855027">+7(925)085-50-27</a> (<span style={{ color: '#2ecc71' }}>WhatsApp</span>,<span style={{ color: '#3498db' }}>Telegram</span>)</p>
                    <p><a href="mailto:support@ronta.ru">support@ronta.ru</a></p>
                </p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold">
                  &copy; 2024 RONTA
                </div>
                {/*
                  <div className="d-flex">
                    <Link to="/terms" className="text-white">
                      Privacy
                    </Link>
                    <Link to="/terms" className="text-white ml-10">
                      Legal
                    </Link>
                    <Link to="/terms" className="text-white ml-10">
                      Contact
                    </Link>
                  </div>
                */}
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">

            {/* begin::Content body */}
            <Switch>
                <Route path="/logout" component={Logout}/>
                <ContentRoute
                  path="/auth/confirm/:email/:code"
                  component={EmailConfirm}
                />
                <ContentRoute
                  path="/auth/registration/confirm"
                  component={RegistrationConfirm}
                />
                {
                  isAuthorized && !isEmailConfirmed ?
                    <>
                      <Redirect to="/auth/registration/confirm"/>
                    </> : ''
                }
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration/success"
                  component={RegistrationSuccess}
                />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login"/>
              </Switch>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2024 RONTA
              </div>
              {/*
                <div className="d-flex order-1 order-sm-2 my-2">
                  <Link to="/terms" className="text-dark-75 text-hover-primary">
                    Privacy
                  </Link>
                  <Link
                    to="/terms"
                    className="text-dark-75 text-hover-primary ml-4"
                  >
                    Legal
                  </Link>
                  <Link
                    to="/terms"
                    className="text-dark-75 text-hover-primary ml-4"
                  >
                    Contact
                  </Link>
                </div>
              */}
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
